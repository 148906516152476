import { render, staticRenderFns } from "./wait-send.vue?vue&type=template&id=89401246&scoped=true&"
import script from "./wait-send.vue?vue&type=script&lang=js&"
export * from "./wait-send.vue?vue&type=script&lang=js&"
import style0 from "./wait-send.vue?vue&type=style&index=0&id=89401246&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89401246",
  null
  
)

export default component.exports