<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/14
  * @BSD
-->
<template>
  <div class="list-wrap">
    <template v-if="list && list.length > 0">
      <van-list
              v-model="loading"
              :finished="isFinsh"
              finished-text="没有更多了"
              @load="init"
      >
        <template v-for="(item, index) in list">
          <is-send :key="index" :item="item"></is-send>
        </template>
      </van-list>
    </template>
    <template v-else>
      <empty />
    </template>
  </div>
</template>

<script>
  import empty from './empty.vue'
  import isSend from './is-send.vue'
  import { getSendList } from '@/common/api/express.js'
  export default {
    components: {
      empty,
      isSend,
    },
    data() {
      return {
        list: [],
        page: 1,
        isFinsh: false,
        loading: false
      };
    },
    mounted () {
      this.init()
    },
    methods:{
      init () {
        getSendList({
          data: {
            page: this.page
          },
          success: res => {
            if (res.code == 200) {
              this.list = this.list.concat(res.data.list)
              this.loading = false;
              this.page = this.page + 1
              if (this.list.length == res.data.total) {
                this.isFinsh = true
              }
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .list-wrap {
    overflow: hidden;
  }
  .list-scroll-view {
    height: calc(100vh - 320px);
    width: 800px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
</style>

