<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/14
  * @BSD
-->
<template>
  <div class="page-wrap" :class="{'pt188': env}">
    <!-- 顶部 -->
    <top-bar-arrow v-if="env">
      <div slot="title" class="title-image">
        商品物流
      </div>
    </top-bar-arrow>
    <div class="main">
      <van-tabs :active="active" class="tab-wrap">
        <van-tab title="全部">
          <all-list />
        </van-tab>
        <van-tab title="待发货">
          <wait-list />
        </van-tab>
        <van-tab title="待评价">
          <comment-list />
        </van-tab>
        <van-tab title="换货中">
          <exchange-list />
        </van-tab>
      </van-tabs>
    </div>

  </div>
</template>

<script>
  import allList from './components/all-list.vue'
  import waitList from './components/wait-list.vue'
  import commentList from './components/comment-list.vue'
  import exchangeList from './components/exchange-list.vue'
  import topBarArrow from '../../components/top-bar-arrow'

  export default {
    components:{
      topBarArrow,
      allList,
      waitList,
      commentList,
      exchangeList
    },
    data() {
      return {
        list: [],
        active: 0,
        env: this.$route.query.env == 'app' ? false : true
      };
    },
    onLoad(option) {
      this.active = parseInt(option.active)
    },
    onShow() {
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  @import "./expressList"
</style>

