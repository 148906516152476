<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/14
  * @BSD
-->
<template>
  <div class="express-item-wrap">
    <div class="express-item box-shadow">
      <div class="product-detail" @click="gotoDetail">
        <div class="left">
          <img :src="item.box_list[0].small_image_url" mode="" />
        </div>
        <div class="center">
          <div class="line1">{{item.box_list[0].product_name}}</div>
        </div>
        <div class="right">
          <div class="text">
            {{ getStatus(item.logistics_order_status) }}
          </div>
          <van-icon name="arrow" size="18" color="#686677"/>
        </div>
      </div>
      <div class="express-detail">
        <div class="left">
          <div class="line">{{ item.pcr_address }}</div>
          <div class="line">{{ item.address }}</div>
        </div>
        <div class="right">
          <div class="btn-normal-small" @click.stop="gotoEdit">修改</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getOrderInfo, } from '@/common/api/express.js'
  import { mapMutations } from 'vuex'
  export default {
    props: {
      item: [Object]
    },
    methods: {
      ...mapMutations(['UPDATE_ORDER_INFO', 'UPDATE_CHOOSE_ADDRESS']),
      getStatus (status) {
        let str = ''
        switch (status){
          case 0:
            str = '待发货'
            break;
          case 1:
            str = '已发货'
            break;
          case 2:
            str = '换货中'
            break;
          case 3:
            str = '订单异常'
            break;
        }
        return str
      },
      gotoEdit () {
        getOrderInfo({
          data: {
            logistics_order_no: this.item.logistics_order_no
          },
          success: res => {
            if (res.code == 200) {
              this.UPDATE_ORDER_INFO(res.data)
              this.UPDATE_CHOOSE_ADDRESS({...res.data})
              this.$router.push({
                name: 'addressEdit',
                params: {
                  orderInfo: res.data
                }
              })
            }
          }
        })

      },
      // 跳转详情
      gotoDetail () {
        this.$router.push({
          name: 'expressDetailWaitting',
          params: {
            logistics_order_no: this.item.logistics_order_no
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../expressList.scss'
</style>

