<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/14
  * @BSD
-->
<template>
  <my-empty class="empty-wrap">
    <div class="message-wrap">
      <div class="line1">暂无商品物流，选购盲盒加入抽盒潮流吧</div>
      <div class="line2">
        <div class="btn">去选盒</div>
      </div>
    </div>
  </my-empty>
</template>

<script>
  import myEmpty from '../../../components/my-empty'
  export default {
    components: {
      myEmpty
    }
  }
</script>

<style lang="scss" scoped>
  .empty-wrap {
    padding-top: 102px;
    &::v-deep .message-wrap {
      & .line1 {
        font-size: 24px;
        color: #BFBFBF;
        letter-spacing: 0;
        text-align: right;
        height: 24px;
        line-height: 24px;
        margin-bottom: 14px;
      }
      & .line2 {
        display: flex;
        align-items: center;
        justify-content: center;
        & .btn{
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #BFBFBF;
          border-radius: 20px;
          width: 96px;
          height: 40px;
          font-size: 24px;
          color: #BFBFBF;
          letter-spacing: 0;
          text-align: center;
          line-height: 24px;
          box-sizing: border-box;
        }
      }
    }
  }
</style>

